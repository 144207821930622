:root { 
--black_900_7f:#0000007f; 
--cyan_A400:#04e4ff; 
--blue_gray_50:#f1f1f1; 
--light_blue_A400:#00b9ff; 
--light_blue_100:#a4dcff; 
--light_blue_500:#01afe9; 
--light_blue_500_01:#009cff; 
--light_blue_400:#30afff; 
--light_blue_800:#0570b8; 
--white_A700_33:#ffffff33; 
--black_900_01:#00042b; 
--black_900_00:#00000000; 
--black_900:#00042a; 
--white_A700_19:#ffffff19; 
--deep_purple_A200:#7748e3; 
--cyan_A400_7f:#04e4ff7f; 
--light_blue_A700_54:#0096ff54; 
--white_A700_4c:#ffffff4c; 
--gray_700:#5f6568; 
--black_900_00_01:#00000400; 
--black_900_a2:#000000a2; 
--blue_700:#3b7bce; 
--amber_500:#f1cd0e; 
--gray_200:#eeebeb; 
--orange_200:#e4c66f; 
--gray_100:#f2f2f2; 
--lime_A700:#90c404; 
--gray_900_7f:#0a0d347f; 
--gray_100_01:#f1f5fe; 
--indigo_900:#1b2c5c; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--indigo_800:#1b398f; 
}